import axios from "axios";
import dayjs, { Dayjs } from "dayjs";
import de from "dayjs/locale/de";
import React, { useEffect, useRef, useState } from "react";

dayjs.locale(de);

interface MealType {
  uuid: string;
  text: string;
  kurz: string;
}

interface Meal {
  uuid: string;
  type: MealType;
  title: string;
  description: string;
  allergens: string;
  price: number;
  date: string;
  created: string;
  updated: string;
}

const formatEuro = (price: number): string =>
  new Intl.NumberFormat("de-DE", { style: "currency", currency: "EUR" }).format(
    price
  );
const ImageRotationSpeed = 10000;

function App() {
  const [meals, setMeals] = useState<Meal[]>([]);
  const [useImage, setUseImage] = useState<boolean>(false);
  const [images, setImages] = useState<string[]>([]);
  const [text, setText] = useState<string>("");
  const [currentDate, setCurrentDate] = useState<Dayjs>(dayjs());
  const mealsWrapper = useRef<HTMLDivElement | null>(null);
  const [useText, setUseText] = useState<boolean>(false);
  const [displayId, setDisplayId] = useState<string>("");
  const [currentTime, setCurrentTime] = useState<string>(
    dayjs().format("DD.MM.YY HH:mm")
  );
  const [rotateImages, setRotateImages] = useState<boolean>(false);
  const [currentRotation, setCurrentRotation] = useState<number>(0);

  const setNextRotation = (rotation: number, length: number) => {
    let newRotation = rotation === length ? 0 : rotation + 1;
    setCurrentRotation(newRotation);

    setTimeout(() => {
      setNextRotation(newRotation, length);
    }, ImageRotationSpeed);
  };

  useEffect(() => {
    axios
      .get<Meal[]>("/meals?date=" + currentDate.format("YYYY-MM-DD"))
      .then((response) => {
        setMeals(response.data);
        setTimeout(() => {
          if (dayjs().add(14, "days").isSame(currentDate, "day")) {
            setCurrentDate(dayjs());
          } else if (currentDate.day() === 5) {
            setCurrentDate(currentDate.add(3, "days"));
          } else {
            setCurrentDate(currentDate.add(1, "day"));
          }
        }, 27000);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [currentDate]);

  useEffect(() => {
    Promise.all([
      axios.get<string>("/display-id"),
      axios.get<{ useImage: boolean; images: string[] }>("/images"),
      axios.get<{ useText: boolean; text: string }>("/text"),
    ]).then((values) => {
      setDisplayId(values[0].data);
      if (values[1].data.useImage) {
        setImages(values[1].data.images);
        setUseImage(values[1].data.useImage);

        if (values[1].data.images.length > 1) {
          setRotateImages(true);
          setNextRotation(0, values[1].data.images.length);
        } else {
          setRotateImages(false);
        }
      }
      setUseText(values[2].data.useText);
      setText(values[2].data.text);
    });

    const setTime = () => {
      setCurrentTime(dayjs().format("DD.MM.YY HH:mm:ss"));
      setTimeout(() => setTime(), 1000);
    };

    window.addEventListener("load", setTime);

    return () => {
      window.removeEventListener("load", setTime);
    };
  }, []);

  return (
    <React.Fragment>
      <div className={"wrapper"}>
        {(useText || useImage) && (
          <div className="top">
            {useImage &&
              rotateImages &&
              images.map((image, index) => (
                <div
                  className="image"
                  key={index}
                  style={{
                    backgroundImage: `url('${image}')`,
                    display: currentRotation === index ? "block" : "none",
                  }}
                />
              ))}
            {useImage && !rotateImages && (
              <div
                className="image"
                style={{ backgroundImage: `url('${images[0]}')` }}
              />
            )}
            {useText && (
              <React.Fragment>
                <h1 className="info">Information</h1>
                <h1 className="text">{text}</h1>
              </React.Fragment>
            )}
          </div>
        )}
        <div className="bottom">
          <h1 className={"header" + (meals.length > 8 ? " small" : "")}>
            {"Menüs vom " + currentDate.format("ddd DD.MM.YY")}
          </h1>
          <div
            ref={mealsWrapper}
            className={"mealsWrapper" + (meals.length > 8 ? " twoRows" : "")}
          >
            {meals.length <= 6 && (
              <div className="meals large">
                {meals.map((meal, index) => (
                  <div className="meal" key={index}>
                    <div className="tag box">{meal.type.kurz}</div>
                    <div className="content box">
                      <h1 className="title">{meal.title}</h1>
                      <h2 className="description">{meal.description}</h2>
                      {/* <h3 className="allergens">{meal.allergens}</h3> */}
                    </div>
                    <div className="box price">{formatEuro(meal.price)}</div>
                  </div>
                ))}
              </div>
            )}
            {meals.length > 6 && meals.length <= 8 && (
              <React.Fragment>
                <div className="meals">
                  {meals
                    .filter((meal) => {
                      return (
                        meal.type.kurz === "S1" ||
                        meal.type.kurz === "S2" ||
                        meal.type.kurz === "S3" ||
                        meal.type.kurz === "S4" ||
                        meal.type.kurz === "W1" ||
                        meal.type.kurz === "W2" ||
                        meal.type.kurz === "D1" ||
                        meal.type.kurz === "WM"
                      );
                    })
                    .map((meal, index) => (
                      <div className="meal" key={index}>
                        <div className="tag box small">{meal.type.kurz}</div>
                        <div className="content box small">
                          <h1 className="title">{meal.title}</h1>
                          <h2 className="description">{meal.description}</h2>
                          {/* <h3 className="allergens">{meal.allergens}</h3> */}
                        </div>
                        <div className="box price small">
                          {formatEuro(meal.price)}
                        </div>
                      </div>
                    ))}
                </div>
                <div className="meals">
                  {meals
                    .filter((meal) => {
                      return (
                        meal.type.kurz === "M1" ||
                        meal.type.kurz === "M2" ||
                        meal.type.kurz === "M3" ||
                        meal.type.kurz === "M4" ||
                        meal.type.kurz === "M5" ||
                        meal.type.kurz === "M6" ||
                        meal.type.kurz === "XXL" ||
                        meal.type.kurz === "FitMe"
                      );
                    })
                    .map((meal, index) => (
                      <div className="meal" key={index}>
                        <div className="tag box small">{meal.type.kurz}</div>
                        <div className="content box small">
                          <h1 className="title">{meal.title}</h1>
                          <h2 className="description">{meal.description}</h2>
                          {/* <h3 className="allergens">{meal.allergens}</h3> */}
                        </div>
                        <div className="box price small">
                          {formatEuro(meal.price)}
                        </div>
                      </div>
                    ))}
                </div>
              </React.Fragment>
            )}
            {meals.length > 8 && (
              <React.Fragment>
                <div className="meals">
                  {meals
                    .filter((meal) => {
                      return (
                        meal.type.kurz === "S1" ||
                        meal.type.kurz === "S2" ||
                        meal.type.kurz === "S3" ||
                        meal.type.kurz === "S4" ||
                        meal.type.kurz === "W1" ||
                        meal.type.kurz === "W2" ||
                        meal.type.kurz === "D1" ||
                        meal.type.kurz === "WM"
                      );
                    })
                    .map((meal, index) => (
                      <div className="meal" key={index}>
                        <div className="content box">
                          <h1 className="title">
                            {meal.type.kurz}: {meal.title}
                          </h1>
                          <h2 className="description large">
                            {meal.description}
                          </h2>
                          {/* <h3 className="allergens">{meal.allergens}</h3> */}
                          <div className="price large">
                            {formatEuro(meal.price)}
                          </div>
                        </div>
                      </div>
                    ))}
                </div>
                <div className="meals">
                  {meals
                    .filter((meal) => {
                      return (
                        meal.type.kurz === "M1" ||
                        meal.type.kurz === "M2" ||
                        meal.type.kurz === "M3" ||
                        meal.type.kurz === "M4" ||
                        meal.type.kurz === "M5" ||
                        meal.type.kurz === "M6" ||
                        meal.type.kurz === "XXL" ||
                        meal.type.kurz === "FitMe"
                      );
                    })
                    .map((meal, index) => (
                      <div className="meal" key={index}>
                        <div className="content box">
                          <h1 className="title">
                            {meal.type.kurz}: {meal.title}
                          </h1>
                          <h2 className="description large">
                            {meal.description}
                          </h2>
                          {/* <h3 className="allergens">{meal.allergens}</h3> */}
                          <div className="price large">
                            {formatEuro(meal.price)}
                          </div>
                        </div>
                      </div>
                    ))}
                </div>
              </React.Fragment>
            )}
          </div>
        </div>
      </div>
      <div id="datetime">
        {currentTime} / Display: {displayId}
      </div>
      <img
        src="http://localhost:3000/basilikum-min.png"
        id="basilikum"
        alt="basilikum"
      />
      <img
        src="http://localhost:3000/bowls-min.png"
        id="bowls"
        alt="bowls"
      />
    </React.Fragment>
  );
}

export default App;
